import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MutualLogo = _resolveComponent("MutualLogo")!
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_NotificationIcon = _resolveComponent("NotificationIcon")!
  const _component_m_btn = _resolveComponent("m-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppSettings = _resolveComponent("AppSettings")!
  const _component_NotificationBar = _resolveComponent("NotificationBar")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("todo: put this into a 'header' component"),
    _createVNode(_component_v_app, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_layout, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_app_bar, {
              elevation: 1,
              density: "compact"
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_MutualLogo)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_app_bar_title, { class: "text-start" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.routeTitle), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_NotificationIcon),
                _createVNode(_component_m_btn, {
                  icon: "mdi-cog",
                  onClick: $setup.showSettings,
                  variant: "text"
                }, null, 8 /* PROPS */, ["onClick"]),
                _createVNode(_component_m_btn, { variant: "text" }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => _cache[0] || (_cache[0] = [
                        _createTextVNode("mdi-account")
                      ])),
                      _: 1 /* STABLE */
                    })
                  ]),
                  append: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.authStore.getName), 1 /* TEXT */)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_menu, {
                      "offset-y": "",
                      activator: "parent"
                    }, {
                      default: _withCtx(() => [
                        _createCommentVNode(" Menu content "),
                        _createVNode(_component_v_list, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item, {
                              button: "",
                              onClick: $setup.logout
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, null, {
                                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                                    _createTextVNode("Log out")
                                  ])),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_main, {
              id: "main-content",
              class: "d-flex justify-center flex-wrap ma-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_view),
                _createVNode(_component_AppSettings, {
                  isActive: $setup.settingsOn,
                  onClosed: $setup.settingsClosed
                }, null, 8 /* PROPS */, ["isActive", "onClosed"]),
                _createVNode(_component_NotificationBar)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}