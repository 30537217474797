import { ref, inject } from "vue";
import { AuthService } from "@/contracts/AuthService";
import { HttpOperationResult, HttpOperationResultBuilder } from "@/contracts/OperationResult";
import { retry, circuitBreaker, handleAll, wrap, ExponentialBackoff, ConsecutiveBreaker, CircuitBreakerPolicy } from "cockatiel";
import { HttpError } from "@/errors/Errors";

type FetchFunction = (url: string, options?: RequestInit) => Promise<Response>;

export function useFetch(authService: AuthService = inject('auth') as AuthService) {
  const isAuthenticated = ref(false);

  const defaultCircuitBreakerPolicy = circuitBreaker(handleAll, {
    halfOpenAfter: 10 * 1000, // 10 seconds
    breaker: new ConsecutiveBreaker(5), // Trips after 5 consecutive failures
  });

  async function fetchWithAuth(url: string, options: RequestInit = {}): Promise<Response> {
    const account = await authService.getActiveAccount();

    if (!account) {
      throw new Error("Unable to fetch configuration info, no logged-in user");
    }

    isAuthenticated.value = true;

    const authHeaders = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${account.idToken}`,
      "X-User-Id": account.username,
    };

    const mergedHeaders = {
      ...(options.headers || {}),
      ...authHeaders,
    };

    return fetch(url, {
      ...options,
      headers: mergedHeaders,
    });
  }

  async function fetchNoAuth(url: string, options: RequestInit = {}): Promise<Response> {
    return await fetch(url, {
      ...options,
      headers: {
        "Content-Type": "application/json",
        ...(options.headers || {}),
      },
    });
  }

  async function fetchResilientWithAuth(url: string, options: RequestInit = {}, testBreaker?: CircuitBreakerPolicy): Promise<Response> {
    const retryPolicy = retry(handleAll, { maxAttempts: 3, backoff: new ExponentialBackoff() });
    const breaker = testBreaker ?? defaultCircuitBreakerPolicy;
    const retryWithBreaker = wrap(retryPolicy, breaker);
    return await retryWithBreaker.execute(() => fetchWithAuth(url, options));
  }

  async function fetchResilientNoAuth(url: string, options: RequestInit = {}): Promise<Response> {
    const retryPolicy = retry(handleAll, { maxAttempts: 3, backoff: new ExponentialBackoff() });
    const retryWithBreaker = wrap(retryPolicy, defaultCircuitBreakerPolicy);
    return await retryWithBreaker.execute(() => fetchNoAuth(url, options));
  }

  async function toOperationResult<T>(
    fetchFn: FetchFunction,
    url: string,
    options: RequestInit = {},
    callback?: (result: HttpOperationResult<T>) => void
  ): Promise<HttpOperationResult<T>> {
    return fetchFn(url, options)
      .then((response) => handleResponse<T>(response))
      .then((data) => {
        const result = createSuccessResult<T>(data);
        if (callback) {
          callback(result);
        }
        return result;
      })
      .catch((error) => {
        const result = handleFetchError<T>(error);
        if (callback) {
          callback(result);
        }
        return result;
      });
  }

  async function handleResponse<T>(response: Response): Promise<T> {
    const text = await response.text();
    const contentType = response.headers.get("Content-Type") || "";

    let parsed: unknown = text;
    if (contentType.includes("application/json") && text) {
      try {
        parsed = JSON.parse(text);
      } catch (err) {
        parsed = text;
      }
    }

    if (!response.ok) {
      throw new HttpError(response.status, response.statusText, parsed);
    }

    return parsed as T;
  }

  function handleFetchError<T>(error: any): HttpOperationResult<T> {
    const builder = new HttpOperationResultBuilder<T>();

    if (error instanceof Error) {
      builder.setException(error);
    }

    return builder.build();
  }

  function createSuccessResult<T>(data: T): HttpOperationResult<T> {
    return new HttpOperationResultBuilder<T>().setData(data).build();
  }

  return {
    fetchWithAuth,
    fetchNoAuth,
    fetchResilientWithAuth,
    fetchResilientNoAuth,
    toOperationResult,
    isAuthenticated,
  };
}
