<template>
	<v-navigation-drawer location="right" v-model="showDrawer" temporary @update:modelValue="handleDrawerUpdate" class="text-left" width="400">
		
		<v-system-bar style="width: 100%; top: 0; left: 0;" >
			<v-icon icon="mdi-eye" class="ms-2" @click="markAllRead" title="Mark all as read"></v-icon>
			<v-icon icon="mdi-delete" class="ms-2" @click="removeAll" title="Remove all"></v-icon>
		</v-system-bar>

		<v-list class="mt-5">
			<v-list-item 
				v-for="notification in appStore.notifications" 
				:key="notification.id" 
				:value="notification"
				class="py-3 text-left"
				:class="{ 'v-list-item--active': !notification.read }"
				@click="selectNotification(notification)">

				<v-list-item-title>{{ notification.title }}</v-list-item-title>

				<v-list-item-subtitle class="text-high-emphasis">{{ notification.message }}</v-list-item-subtitle>

				<template v-slot:append>
					<v-list-item-action class="flex-column align-end">
						<small class="text-high-emphasis opacity-60">{{ formatDate(notification.created) }}</small>

						<v-spacer></v-spacer>

						<v-container class="align-end text-right ma-0 pa-0">
							<v-tooltip text="Remove">
								<template v-slot:activator="{ props }">
									<v-icon @click="remove(notification)" v-bind="props">mdi-delete</v-icon>
								</template>
							</v-tooltip>
						</v-container>
					</v-list-item-action>
				</template>
			</v-list-item>
			<v-container v-if="appStore.notifications.length === 0">
				<p class="text-italic">You're all caught up.</p>
			</v-container>
		</v-list>
	</v-navigation-drawer>
</template>

<script lang="ts">
import { ref, watchEffect, onMounted, onUnmounted, inject } from 'vue';
import { Tell } from '@/services/Tell';
import { AppNotification } from '@/models/NotificationModels';
import { useAppStore } from '@/stores/app.store';
import { EventBus, Events } from '@/contracts/EventBus';
import MBtn from '@/components/BaseButton.vue';
import moment from 'moment';

export default {
	name: 'NotificationBar',
	components: {
		//MBtn
	},
	setup() {
		const appStore = useAppStore();
		const showDrawer = ref<boolean>(false);
		const eventBus = inject<EventBus>('eventBus');
		const tell = inject<Tell>('tell');

		const markAsRead = (notification: AppNotification) => {
			notification.read = true;
		};

		const markAllRead = () => {
			appStore.notifications.forEach(n => n.read = true);
		};

		const remove = (notification: AppNotification) => {
			const index = appStore.notifications.indexOf(notification);
			if (index > -1) {
				appStore.notifications.splice(index, 1);
			}

			//hide the drawer if the last notification was removed
			if (appStore.notifications.length === 0) {
				handleDrawerUpdate(false);
			}
		};

		const formatDate = (date: Date): string =>{
			return moment(date).fromNow();
		}

		const removeAll = () => {
			appStore.notifications = [];
			handleDrawerUpdate(false);
		};

		const handleEventBarToggled = (data: { show: boolean }) => {
			showDrawer.value = data.show;
		};

		const handleDrawerUpdate = (value: boolean) => {
			showDrawer.value = value;

			eventBus.emit(Events.NOTIFICATION_BAR_TOGGLED, { show: showDrawer.value });
		};

		const selectNotification = (notification: AppNotification) => {
			if (!notification.read) {
				markAsRead(notification);
			}
		};

		onMounted(async () => {
			//get on the bus!
			eventBus.on(Events.NOTIFICATION_BAR_TOGGLED, handleEventBarToggled);
		});

		// Clean up event listener
		onUnmounted(() => {
			//get off the bus!
			eventBus.off(Events.NOTIFICATION_BAR_TOGGLED, handleEventBarToggled);
		});

		return {
			appStore,
			showDrawer,
			selectNotification,
			markAsRead,
			markAllRead,
			remove,
			removeAll,
			formatDate,
			handleDrawerUpdate
		}
	}
}
</script>

<style scoped>
</style>