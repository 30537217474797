import { ref, inject } from "vue";
import { CommercialApiContract } from "@/contracts/CommercialApiContract";
import { HttpOperationResult } from "@/contracts/OperationResult";
import { PolicyScope } from "@/contracts/PolicyService";
//import { MessageRequest, MessageRequest2 } from "@/models/MessageModel";
import { DocGenPutRequest } from "@/models/PrintDocumentModel";
import * as bdModels from "@/models/BulkDeductibleViewModels";
import * as ratingModels from "@/models/RatingModels";
import * as policyModels from "@/models/PolicyModels";
import * as configModels from "@/models/ConfigModels";
import { useFetch } from "@/composables/UseFetch";
import { MessageEnvelope } from "@/contracts/MessageService";

export function useCommercialApi(
  apiConfig: CommercialApiContract,
  fetchUtils: ReturnType<typeof useFetch> = useFetch(inject('auth')) // default to useFetch with auth service
) {
  const loading = ref(false);

  async function docsDocGenRequest<TPayload, TResult>(messageRequest: TPayload, callback?: (result: HttpOperationResult<TResult>) => void): Promise<HttpOperationResult<TResult>> {
    loading.value = true;
    return fetchUtils.toOperationResult<TResult>(
      fetchUtils.fetchResilientWithAuth,
      `${apiConfig.endpoint}/messaging/topic`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(messageRequest),
      },
      callback ? (result) => callback(result) : undefined
    ).finally(() => {
      loading.value = false;
    });
  }

  async function putDocGenForm<T>(
    docGenPutRequest: DocGenPutRequest,
    callback?: (result: HttpOperationResult<T>) => void
  ): Promise<HttpOperationResult<T>> {
    loading.value = true;

    return fetchUtils.toOperationResult<T>(
      fetchUtils.fetchResilientWithAuth,
      `${apiConfig.endpoint}/documents/form/docgen/${docGenPutRequest.policyNumber}`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(docGenPutRequest),
      },
      callback ? (result) => callback(result) : undefined
    ).finally(() => {
      loading.value = false;
    });
  }

  async function getBulkDeductibles(policyNumber: string): Promise<bdModels.BulkDeductibleViewModel> {
    return await fetch(`${apiConfig.endpoint}/policy/bulkdeductibles?policyNumber=${policyNumber}`)
      .then(response => response.json());
  }

  async function updateBulkDeductibles(post: bdModels.UpdateBulkDeductiblePost): Promise<Response> {
    const response = await fetch(`${apiConfig.endpoint}/policy/updatedeductibles`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;'
      },
      body: JSON.stringify(post),
    });

    if (response.ok) {
      return response;
    } else {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }
  }

  async function ratePolicy(reateRequest: ratingModels.RateRequest): Promise<Response> {
    const response = await fetch(`${apiConfig.endpoint}/rating/rate/policy`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;'
      },
      body: JSON.stringify(reateRequest)
    });

    if (response.ok) {
      return response;
    } else {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }
  }

  async function searchPolicies(searchRequest: policyModels.PolicySearchRequest): Promise<policyModels.PolicySearchResponse> {
    const response = await fetch(`${apiConfig.endpoint}/policy/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(searchRequest)
    });

    if (response.ok) {
      const result: policyModels.PolicySearchResponse = await response.json();
      return result;
    }
    else {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }
  }

  async function getRatingDetails(documentId: string): Promise<ratingModels.GetRateDetailResponse> {
    const response = await fetch(`${apiConfig.endpoint}/rating/ratingDetails/${documentId}`, {
      method: 'GET'
    });

    if (response.ok) {
      const result: ratingModels.GetRateDetailResponse = await response.json();
      return result;
    }
    else {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }
  }

  async function getCoverageSummary(documentId: string): Promise<ratingModels.CoverageSummaryItem[]> {
    const response = await fetch(`${apiConfig.endpoint}/rating/coverageSummary/${documentId}`, {
      method: 'GET'
    });

    if (response.ok) {
      const result: ratingModels.CoverageSummaryItem[] = await response.json();

      return result;
    }
    else {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }
  }

  async function getLossRatio(policyNumber: string): Promise<policyModels.LossRatioResponse> {
    const response = await fetch(`${apiConfig.endpoint}/workbench/policyloss/${policyNumber}`);

    if (response.ok) {
      const result: policyModels.LossRatioResponse = await response.json();

      return result;
    }
    else {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }
  }

  async function getMessageClientConnection(hubName: string): Promise<configModels.GetMessageClientConnectionInfoResponse> {
    const response = await fetchUtils.fetchWithAuth(`${apiConfig.endpoint}/config/for/messaging/${hubName}`, {
      method: 'POST'
    });

    if (response.ok) {
      const result: configModels.MessageClientConnectionInfo = await response.json();

      return new configModels.GetMessageClientConnectionInfoResponse({
        connectionInfo: result,
        success: true
      });
    }
    else {
      return new configModels.GetMessageClientConnectionInfoResponse({
        connectionInfo: null,
        message: `Error: ${response.status} - ${response.statusText}`,
        success: false
      });
    }
  }

  async function getPolicyJSON(policyNumber: string, scope: PolicyScope): Promise<string> {
    const response = await fetch(`${apiConfig.endpoint}/policy/${policyNumber}/${scope}`, {
      method: 'GET'
    });

    if (response.ok) {
      const result: string = await response.json();
      return result;
    }
    else {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }
  }

  return {
    docsDocGenRequest,
    putDocGenForm,
    getBulkDeductibles,
    updateBulkDeductibles,
    ratePolicy,
    searchPolicies,
    getRatingDetails,
    getCoverageSummary,
    getLossRatio,
    getMessageClientConnection,
    getPolicyJSON,
    loading,
  };
}
