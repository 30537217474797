import { Notyf } from "notyf";
import 'notyf/notyf.min.css'; // for CSS
import  '../assets/styles/custom-notyf.css'; // for custom CSS
import { EventBus, Events } from '@/contracts/EventBus';
import App from "@/App.vue";
import { AppNotification } from "@/models/NotificationModels";

export class Tell {
	private notyf: Notyf;
	private eventBus?: EventBus;

	private _defaultOptions = {
		duration: 5000,
		dismissible: true,
		ripple: false
	};

	constructor(eventBus?: EventBus) {		
		this.notyf = new Notyf({
			position: {
				x: 'right',
				y: 'top'
			},
			duration: this._defaultOptions.duration,
			types: [
				{
					...this._defaultOptions,
					type: 'success',
					background: 'rgb(var(--v-theme-success))'
				},
				{
					...this._defaultOptions,
					type: 'warning',
					background: 'rgb(var(--v-theme-warning))'
				},
				{
					type: 'error',
					background: 'rgb(var(--v-theme-error))',
					duration: this._defaultOptions.duration,
					dismissible: true
				},
				{
					type: 'info',
					background: 'rgb(var(--v-theme-info))',
					duration: this._defaultOptions.duration,
					dismissible: true
				}
			]
		});

		this.eventBus = eventBus;
	}

	private get(): Notyf {
		return this.notyf;
	}

	public success(message: string, duration: number = this._defaultOptions.duration): void {
		this.get().open({
			message: message,
			duration: duration,
			type: 'success'
		});

		//todo: emit event on the event bus?
	}

	public error(message: string, duration: number = this._defaultOptions.duration): void {
		this.get().open({
			message: message,
			duration: duration,
			type: 'error',
		});

		//todo: emit event?
	}

	public warning(message: string, duration: number = this._defaultOptions.duration): void {
		this.get().open({
			message: message,
			duration: duration,
			type: 'warning',
		});

		//todo: emit event?
	}

	public info(message: string, duration: number = this._defaultOptions.duration): void {
		this.get().open({
			message: message,
			duration: duration,
			type: 'info',
		});

		//todo: emit event?
	}
}