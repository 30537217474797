export class AppNotification {
	id: number;
	title: string;
	message: string;
	created: Date;
	read: boolean;
	clicked?: (notification: AppNotification) => void;

	constructor(init?: Partial<AppNotification>) {
		Object.assign(this, init);

		if(!this.created) {
			this.created = new Date();
		}

		this.id = this.created.getTime();
	}
}