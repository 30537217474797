export enum RateSource {
	Point = 'point',
	RsxDraft = 'rsxDraft',
	RsxApproval = 'rsxApproval',
	RsxReleased = 'rsxReleased'
}

export interface ContextInfo {
	userId: string;
}
//TODO: Clean up extra fields
export class RateRequest {
	policyNumber: string;
	ratingEnvironment: RateSource;
	saveRatesToDB2: boolean;
	correlationId: string;
	contextInfo: ContextInfo;
	requestSource: string;
	buildTraceWorksheet: boolean;
	treatVerifiedAsEndorsement: boolean;

	constructor() {
		this.policyNumber = "";
		this.ratingEnvironment = RateSource.RsxDraft;
		this.saveRatesToDB2 = false;
		this.correlationId = "";
		this.contextInfo = {
			userId: ""
		};
		this.buildTraceWorksheet = false;
		this.treatVerifiedAsEndorsement = true;
	}
}

export class GetRateDetailResponse {
	id: string = null;
	context: {
		userInfo: string
		//TODO: add more context information here
	} = null;
	policyNumber: string = null;
	ratingEnvironment: RateSource = null;
	totalPremium: number = null;
	timeStamp: string = null;
	ratingDetails: RateRequestDetails = null;
}

export class RateRequestDetails {
	ratingSystemKey: string;
	totalPremium: number;
	requestResponsePairs: RequestResponsePairs[];
}

export class RequestResponsePairs {
	request: object;
	response: object;
}

export class CoverageSummaryItem {
	insuranceLine: string = null;
	coverageType: string = null;
	pointPremium: number;
	premium: number;
	coveragePremium: number;
	linePremium: number;
	policyPremium: number;
	finalRate: number = null;
	location: number = null;
	subLocation: number = null;
	unit: number = null;
	sequence: number = null;
}