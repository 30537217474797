<template>
	<v-treeview 
		:items="treeNodes" 
		item-value="id" 
		density="compact"
		activatable>
	</v-treeview>
</template>

<script lang="ts">
import { ref } from 'vue';

export interface JsonTreeViewProps {
	treeNodes: Array<TreeNode>;
}

export class TreeNode {
	id: string;
	title: string;
	children?: Array<TreeNode> | null;
}

export function BuildTreeViewItems(obj: any, parentKey = ''): Array<TreeNode> {
	return Object.keys(obj).map(key => {
		const fullKey = parentKey ? `${parentKey}.${key}` : key;
		let itemValue = obj[key];

		if (itemValue) {
			if (itemValue == "[object Object]") {
				itemValue = "";
			} else {
				itemValue = ` : ${obj[key]}`;
			}
		}
		const item: TreeNode = {
			id: fullKey,
			title: `${key}${itemValue}`,
			children: typeof obj[key] === 'object' && obj[key] !== null ? BuildTreeViewItems(obj[key], fullKey) : null
		};
		return item;
	});
}

export default {
	name: 'JsonTreeView',
	props:  {
		treeNodes: {
			type: Array<TreeNode>,
			required: true
		}
	},
	computed: {
	},

	setup(props: JsonTreeViewProps) {
		// const treeNodes = ref<Array<TreeNode>>([]);
		
		// return {
		// 	treeNodes
		// }
	}
}
</script>