import { CommercialApiContract } from "@/contracts/CommercialApiContract";
import * as bdModels from '@/models/BulkDeductibleViewModels';
import * as policyModels from '@/models/PolicyModels';

export interface PolicyService {
	apiConfig: CommercialApiContract;

	getBulkDeductibles(policyNumber: string): Promise<bdModels.BulkDeductibleViewModel>;

	/// <summary>
	/// Updates bulk deductibles for a given policy
	/// TODO: 
	/// </summary>
	updateBulkDeductibles(post: bdModels.UpdateBulkDeductiblePost): Promise<Response>;

	/// <summary>
	/// Searches for policies based on the provided search criteria
	/// </summary>
	searchPolicies(post: policyModels.PolicySearchRequest): Promise<policyModels.PolicySearchResponse>;

	/// <summary>
	/// Gets raw JSON data about a specific policy (identified by number) and scope (verified vs pending).
	/// </summary>
	getPolicyJSON(policyNumber: string, scope: PolicyScope): Promise<string>;

	/// <summary>
	/// Gets the profit and loss for a given policy
	/// </summary>
	getLossRatio(policyNumber: string): Promise<policyModels.LossRatioResponse>;
}

export enum PolicyScope {
	Verified = 'Verified',
	Pending = 'Pending'
}