export class HttpError extends Error {
    status: number;
    statusText: string;
    body: unknown;

    constructor(status: number, statusText: string, body: unknown) {
        super(`${status} ${statusText || 'HTTP Error'}`);
        this.status = status;
        this.statusText = statusText;
        this.body = body;
    }
}

export class UnhandledOperationError extends Error {
    statusText: string;
    body?: unknown;

    constructor(statusText?: string, body?: unknown) {
        const message = statusText || 'Unhandled Operation Error';
        super(message);

        this.name = 'UnhandledOperationError';
        this.statusText = message;
        this.body = body;
    }
}
