import telemetry from "./telemetry";
import { Tell } from "./Tell";
import { EventBus } from "@/contracts/EventBus";

export function ErrorService(errorData: any, serviceName: string, customErrorMsg?: string) {
	const tell = new Tell();

	switch (errorData.status) {
		case 404:
			errorData.message = 'Call encountered error 404: Path not found';
			break;
		case 500:
			errorData.message = 'Call encountered error 500: Internal server error';
			break;
		case 401:
			errorData.message = 'Call encountered error 401: Unauthorized';
			break;
		case 403:
			errorData.message = 'Call encountered error 403: Forbidden';
			break;
		case 400:
			errorData.message = 'Call encountered error 400: Bad request';
			break;
		case 504:
			errorData.message = 'Call encountered error 504: Gateway timeout';
			break;
		default:
			errorData.message = 'Call encountered error: Unknown error';
			break;
	}

	if (customErrorMsg) {
		errorData.message = customErrorMsg;
	}

	telemetry.trackException(errorData);
	tell.error(errorData.message);
}