<template>
	<WaitDialog :isActive="isLoading" title="Loading data..." />
	<v-container class="text-start" >
		<v-row>
			<v-col cols="12" md="12">
				<JsonTreeView :treeNodes="policyProperties" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script lang="ts">
import { ref, onMounted, inject } from 'vue';
import { useRoute } from 'vue-router';
import WaitDialog from '@/components/WaitDialog.vue';
//import MBtn from '@/components/BaseButton.vue';
import { Tell } from '@/services/Tell';
import { PolicyScope, PolicyService } from '@/contracts/PolicyService';
import { TreeNode, BuildTreeViewItems } from '@/components/JsonTreeView.vue';
import JsonTreeView from '@/components/JsonTreeView.vue';

export default {
	name: 'ViewPolicy',
	route: {
		path: '/policy/json/:policyNumber',
		meta: {
			requiresAuth: true,
			title: 'View Policy'
		}
	},
	components: {
		WaitDialog,
		JsonTreeView
		//MBtn
	},
	props: {
		//these properties are exposed to consumers of this component
	},
	setup() {
		const route = useRoute();
		const dialog = ref<boolean>(false);
		const policyNumber = ref<string | null>(null);
		const isLoading = ref<boolean>(false);
		const errorMessage = ref<string | null>(null);
		const policyProperties = ref<Array<TreeNode>>([]);
		const policyService = inject('policyService') as PolicyService;
		const tell = inject('tell') as Tell;

		const confirmDialog = () => {
			dialog.value = false;
		}

		const loadData = async function () {
			isLoading.value = true;

			try {
				// get policy JSON from API
				policyNumber.value = route.params.policyNumber as string;

				const jsonResponse = await policyService.getPolicyJSON(policyNumber.value, PolicyScope.Verified);

				const treeViewitems = BuildTreeViewItems(jsonResponse);

				policyProperties.value = treeViewitems;

			} catch (error) {
				console.log("error", error);
				errorMessage.value = "An error occurred while loading data.";
			} finally {
				isLoading.value = false;
			}
		}

		onMounted(async () => {
			await loadData();
		});

		return {
			dialog,
			isLoading,
			confirmDialog,
			tell,
			policyProperties, 
			JsonTreeView
		}
	}
}
</script>
<style scoped>
button {
	margin-right: var(--v-spacing-default);
}
</style>