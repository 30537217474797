import { inject, ref } from 'vue';
import type { Tell } from '@/services/Tell';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { BrokenCircuitError } from "cockatiel";

type MessageResult = string | {
    userErrorMessage: string;
    logErrorMessage: string;
};

function capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function useOperationMessaging() {
    const tell = inject('tell') as Tell | undefined;
    const telemetry = inject('telemetry') as ApplicationInsights | undefined;
    const isLoading = ref(false);

    type ActionStatus = 'start' | 'success' | 'error';

    const verbs: Record<string, string> = {
        GenerateDocuments: 'document generation',
        LoadDocuments: 'load documents',
    };

    function trackTelemetryEvent(
        action: string,
        status: ActionStatus,
        logMessage?: string,
        error?: unknown
    ) {
        if (!telemetry) return;

        const eventName = `${action}_${status}`;
        const properties: Record<string, any> = {
            action,
            status,
            timestamp: new Date().toISOString(),
        };

        if (status === 'error') {
            if (error instanceof Error) {
                properties.errorMessage = error.message;
                properties.stack = error.stack;
            }

            if (logMessage) {
                telemetry.trackTrace(
                    {
                        message: logMessage,
                        severityLevel: 3,
                    },
                    properties
                );
            }
        }

        telemetry.trackEvent({ name: eventName }, properties);
    }

    async function withMessaging<T>(
        action: string,
        operation: () => Promise<T>,
        resolver = resolveMessage
    ): Promise<T> {
        if (!tell) throw new Error('tell not provided');

        const startMessage = resolver(action, 'start');
        tell.info(startMessage as string);
        trackTelemetryEvent(action, 'start');

        isLoading.value = true;

        try {
            const result = await operation();

            const successMessage = resolver(action, 'success');
            tell.info(successMessage as string);
            trackTelemetryEvent(action, 'success');

            return result;
        } catch (error) {

            const errorMessage = resolver(action, 'error', error);

            if (error instanceof BrokenCircuitError) {
                trackTelemetryEvent(action, 'error', 'Circuit Breaker Active', error);
            }

            if (typeof errorMessage === 'string') {
                tell.error(errorMessage);
                trackTelemetryEvent(action, 'error', errorMessage, error);
            } else {
                tell.error(errorMessage.userErrorMessage);
                trackTelemetryEvent(action, 'error', errorMessage.logErrorMessage, error);
            }
        } finally {
            isLoading.value = false;
        }
    }

    function resolveMessage(action: string, status: ActionStatus, error?: unknown): MessageResult {
        const base = verbs[action] || 'operation';

        switch (status) {
            case 'start':
                return `Starting ${base}...`;
            case 'success':
                return `${capitalize(base)} completed successfully!`;
            case 'error': {
                const userErrorMessage = `An error occurred during ${base}. Please try again or contact support.`;
                const logErrorMessage = error instanceof Error
                    ? `[${action}] ${error.message} | stack: ${error.stack}`
                    : `[${action}] Unknown error`;

                return { userErrorMessage, logErrorMessage };
            }
        }
    }

    return {
        withMessaging,
        isLoading,
    };
}

