export type MessageType = 'error' | 'warning' | 'info';

export type Message = {
  type: MessageType;
  content: string;
};

export type OperationResult<T> = {
  success: boolean;
  data?: T;
  hasException: boolean;
  exception?: unknown;
  hasErrorMessages: boolean;
  hasInfoMessages: boolean;
  messages: Message[];
};


export type HttpOperationResult<T> = OperationResult<T> & {
  httpStatus: number;
};

export class OperationResultBuilder<T> {
  protected result: Omit<OperationResult<T>, 'success'>;

  constructor() {
    this.result = {
      hasException: false,
      hasErrorMessages: false,
      hasInfoMessages: false,
      data: undefined,
      messages: [] as Message[],
    };
  }

  get success(): boolean {
    return !this.result.hasErrorMessages && !this.result.hasException;
  }

  setData(data: T): this {
    this.result.data = data;
    return this;
  }

  addError(error: string): this {
    this.result.messages.push({ type: 'error', content: error });
    this.result.hasErrorMessages = true;
    return this;
  }

  addInfo(info: string): this {
    this.result.messages.push({ type: 'info', content: info });
    this.result.hasInfoMessages = true;
    return this;
  }

  setException(exception: unknown): this {
    this.result.hasException = true;
    this.result.exception = exception;
    return this;
  }

  build(): OperationResult<T> {
    return {
      ...this.result,
      success: this.success,
    };
  }

  getResult(): OperationResult<T> {
    return new Proxy(this.result, {
      get: (target, prop) => {
        if (prop === 'success') {
          return this.success;
        }
        return Reflect.get(target, prop);
      },
    }) as OperationResult<T>;
  }
}

export class HttpOperationResultBuilder<T> extends OperationResultBuilder<T> {
  constructor() {
    super();
    (this.result as HttpOperationResult<T>).httpStatus = 200;
  }

  setStatus(httpStatus: number): this {
    (this.result as HttpOperationResult<T>).httpStatus = httpStatus;
    return this;
  }

  build(): HttpOperationResult<T> {
    return {
      ...this.result,
      success: this.success,
      httpStatus: (this.result as HttpOperationResult<T>).httpStatus,
    };
  }
}

