<template>
	<!--todo: put this into a 'header' component-->
	<v-app>
		<v-layout>
			<v-app-bar :elevation="1" density="compact">
				<template v-slot:prepend>
					<MutualLogo />
				</template>

				<v-app-bar-title class="text-start">{{ routeTitle }}</v-app-bar-title>

				<NotificationIcon />

				<m-btn icon="mdi-cog" @click="showSettings" variant="text"></m-btn>
				<m-btn variant="text">
					<template v-slot:prepend>
						<v-icon>mdi-account</v-icon>
					</template>
					<template v-slot:append>
						{{ authStore.getName }}
					</template>

					<v-menu offset-y activator="parent">
						<!-- Menu content -->
						<v-list>
							<v-list-item button @click="logout">
								<v-list-item-title>Log out</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</m-btn>
			</v-app-bar>
			<v-main id="main-content" class="d-flex justify-center flex-wrap ma-0">
				<router-view />
				<AppSettings :isActive="settingsOn" @closed="settingsClosed" />
				<NotificationBar />
				
			</v-main>
		</v-layout>
	</v-app>
</template>
<script lang="ts">

import { useRoute } from 'vue-router';
import { ref, watchEffect, onMounted, inject } from 'vue';
import { AuthService } from '@/contracts/AuthService';
import { useAuthStore } from '@/stores/auth.store';
import { AppNotification } from './models/NotificationModels';
import AppSettings from '@/components/AppSettings.vue';
import MutualLogo from '@/components/MutualLogo.vue';
import NotificationIcon from '@/components/NotificationIcon.vue';
import NotificationBar from '@/components/NotificationBar.vue';
import MBtn from '@/components/BaseButton.vue';

export default {
	name: 'App',
	components: {
		AppSettings,
		MutualLogo,
		NotificationIcon,
		NotificationBar,
		MBtn
	},
	setup() {
		const route = useRoute();
		const routeTitle = ref<string>('');
		const auth = inject<AuthService>('auth');
		const settingsOn = ref<boolean>(false);

		const authStore = useAuthStore();

		const messages = ref<number>(0);

		const showSettings = () => {
			settingsOn.value = true;
		}

		const showNotifications = ref<boolean>(false);

		const settingsClosed = () => {
			settingsOn.value = false;
		}

		const markAsRead = (notification: AppNotification) => {
            notification.read = true;
            // Optionally, emit an event or update the notifications array
        };

		watchEffect(() => {
			routeTitle.value = String(route.meta.title);
		});

		onMounted(async () => {
			//any logic in here?
			//console.log('mounted', authStore.getName);
		});

		return {
			authStore,
			messages,
			settingsOn,
			routeTitle,
			showNotifications,
			showSettings,
			markAsRead,
			settingsClosed,
			logout: async () => {
				if (auth) {
					auth.logout();
				}
			}
		}
	}
}
</script>

<style>
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}
</style>
