export function encodeToUint8Array(payload: unknown): Uint8Array {
    return new TextEncoder().encode(JSON.stringify(payload));
}

export function decodeFromUint8Array<T>(data: Uint8Array): T | string {
    const decoded = new TextDecoder().decode(data);
    try {
        return JSON.parse(decoded) as T;
    } catch {
        return decoded;
    }
}

export function isUint8Array(value: unknown): value is Uint8Array {
    return (
        value != null &&
        typeof value === 'object' &&
        value.constructor &&
        value.constructor.name === 'Uint8Array'
    );
}

