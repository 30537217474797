import { createApp } from 'vue'
import App from './App.vue'
import { router } from '@/services/router'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { Tell } from '@/services/Tell'
import { AuthService } from '@/contracts/AuthService'
import { EntraAuthService } from '@/services/EntraAuthService'
import { CommercialApi } from '@/services/CommercialApi'
import { EntraAuthConfig as entraAuthConfig } from '@/services/EntraAuthConfig'
import { CommercialApiConfig as commercialApiConfig } from '@/services/CommercialApiConfig'
import { SignalRMessageService } from '@/services/SignalRMessageService'
import telemetry from '@/services/telemetry'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import './assets/styles/main.scss'
import 'vuetify/styles'
import "@mdi/font/css/materialdesignicons.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { GoogleMap } from 'vue3-google-map';
import { useAppStore } from '@/stores/app.store';
import colors from 'vuetify/util/colors'
import { AuthenticatedFetch, ResilientAuthenticatedFetch } from './services/AuthenticatedFetch'
import { VTreeview } from 'vuetify/labs/VTreeview'
import { TinyEmitterEventBus } from './services/TinyEmitterEventBus'
import { EventBus } from '@/contracts/EventBus'
import { PolicyService } from '@/contracts/PolicyService'
import { RateService } from '@/contracts/RateService'
import { ConfigService } from '@/contracts/ConfigService'
import { PrintService } from '@/contracts/PrintService'
import { MessageService } from '@/contracts/MessageService'

export function startApp(authService: AuthService,
	policyService: PolicyService,
	rateService: RateService,
	configService: ConfigService,
	printService: PrintService,
	messageService: MessageService,
	eventBus: EventBus
) {
	//const data = await mockService.getData();
	const app = createApp(App);

	const pinia = createPinia();

	pinia.use(piniaPluginPersistedstate);

	app.use(router);
	app.use(pinia);

	// Detect system preference for dark mode
	const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
	let defaultTheme = prefersDarkMode ? 'dark' : 'light';

	// Check the app store for current settings
	const appStore = useAppStore();

	// if the app store has settings; use those
	if (appStore.getTheme != null) {
		if (appStore.getTheme != 'system') {
			defaultTheme = appStore.getTheme;
		}
	}
	else {
		// save this to the store
		if (prefersDarkMode) {
			appStore.setTheme('system');
		}
		else {
			appStore.setTheme(defaultTheme);
		}
	}

	const vuetify = createVuetify({
		components,
		directives,
		icons: {
			defaultSet: 'mdi',
			aliases,
			sets: {
				mdi,
			},
		},
		theme: {
			defaultTheme: defaultTheme,
			themes: {
				light: {
					dark: false,
					colors: {
						primaryMoe: '#4d7e5a',
						primary: '#245e36',
						secondary: '#d67c3b',
						accent: '#4d7e5a',
						error: '#c94241',
						info: '#6099ac',
						success: '#4d7e5a',
						warning: '#d67c3b',
						buttonColor: '#245e36',
						warningLabel: colors.yellow.accent2,
						warningAddress: colors.pink.lighten3
					},
				},
				dark: {
					dark: true,
					colors: {
						primary: '#4d7e5a',
						secondary: '#6d6e64',
						accent: '#80b9cd',
						error: '#c94241',
						info: '#a1a096',
						success: '#4d7e5a',
						warning: '#d67c3b',
					},
				},
			}
		}
	});

	app.use(vuetify);

	app.use(VueGoogleMaps, {
		load: {
			key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
			libraries: ['places'],
		}
	});

	app.component('GoogleMap', GoogleMap);
	app.component('VTreeview', VTreeview);

	app.provide('auth', authService);
	app.provide('telemetry', telemetry);
	app.provide('tell', new Tell(eventBus));
	app.provide('policyService', policyService);
	app.provide('rateService', rateService);
	app.provide('configService', configService);
	app.provide('printService', printService);
	app.provide('messageService', messageService);
	
	app.provide('eventBus', eventBus);
	
	app.mount('#app');
}

if (process.env.VUE_APP_IS_TESTING !== 'true') {
	//start regularly
	const authService = new EntraAuthService(entraAuthConfig);
	const commercialApi = new CommercialApi(commercialApiConfig, new ResilientAuthenticatedFetch(new AuthenticatedFetch(authService)));
	const signalRService = new SignalRMessageService(commercialApi, telemetry);
	const eventBus = new TinyEmitterEventBus();

	startApp(authService, 
		commercialApi,
		commercialApi,
		commercialApi,
		commercialApi,
		signalRService,
		eventBus
	);
}
// startApp(new EntraAuthService(entraAuthConfig));