import { Tell } from './Tell';

const tell = new Tell();

export const GhostDraftService = {

	async getDatCaptureAuthToken(): Promise<string> {
		const url = `${process.env.VUE_APP_MOE_COMMERCIAL_API_URL}/ghostdraft/dataCaptureToken`;
		const response = await fetch(url.toString(), {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-api-key': process.env.VUE_APP_MOE_COMMERCIAL_API_KEY,
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			tell.error(errorData.message);
			throw new Error(errorData.message);
		}
		
		const responseData = await response.text();

		return responseData;
  }
}