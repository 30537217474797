import { TinyEmitter } from 'tiny-emitter';
import { EventBus } from '@/contracts/EventBus';

export class TinyEmitterEventBus implements EventBus {
	private emitter: TinyEmitter;

	constructor() {
		this.emitter = new TinyEmitter();
	}

	on(event: string, callback: (...args: any[]) => void): void {
		this.emitter.on(event, callback);
	}

	once(event: string, callback: (...args: any[]) => void): void {
		this.emitter.once(event, callback);
	}

	off(event: string, callback: (...args: any[]) => void): void {
		this.emitter.off(event, callback);
	}

	emit(event: string, ...args: any[]): void {
		this.emitter.emit(event, ...args);
	}
}