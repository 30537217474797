// As of 2024-02-3 we are either accepting HTML content or PDF content to print documents.
// Interface will allow HTML or PDF content to be passed to the print service for testing. 

export type GenerateDocsRequest = {
	policyNumber: string;
	requestedBy: string;
};

export type GenerateDocsResponse = Record<string, never>;

export class DocGenDocumentSource {
	policyNumber: string;
	contentSource: 'DocProd' | 'GhostDraft';
	contentUrl: string;

	constructor(init?: Partial<DocGenDocumentSource>) {
		Object.assign(this, init);
	}
}

export interface DocGenFormValues {
	[key: string]: string | number | boolean | null;
}

export class DocGenPutRequest {
	fields: DocGenFormValues;
	policyNumber: string;
	constructor(form: DocGenFormValues, policyNumber: string) {
		this.fields = form;
		this.policyNumber = policyNumber;
	}
}

export class DocGenPutResponse {
	id: string;
	documentSaved: boolean;
}

// todo: remove this when CommercialApi is removed
export class RequestDocumentResponse {
	statusCode: number;
	messageType: string;
	message: string;
	messageProperties: {
		correlationId: string;
		contentType: string;
		timeToLive: number;
		customProperties: [];
	}

	constructor(init?: Partial<RequestDocumentResponse>) {
		Object.assign(this, init);
	}
}
