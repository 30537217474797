import { CommercialApiContract } from "@/contracts/CommercialApiContract";
import { ResilientAuthenticatedFetch } from "@/services/AuthenticatedFetch";
import { PolicyScope, PolicyService } from "@/contracts/PolicyService";
import { RateService } from "@/contracts/RateService";
import { ConfigService } from "@/contracts/ConfigService";
import * as bdModels from "@/models/BulkDeductibleViewModels";
import * as ratingModels from "@/models/RatingModels";
import * as policyModels from "@/models/PolicyModels";
import * as configModels from "@/models/ConfigModels";
import * as printModels from "@/models/PrintDocumentModel";
import { PrintService } from "@/contracts/PrintService";
import { HttpOperationResult, HttpOperationResultBuilder } from "@/contracts/OperationResult";

export class CommercialApi implements PolicyService,
	RateService,
	PrintService,
	ConfigService {

	apiConfig: CommercialApiContract;
	authFetch: ResilientAuthenticatedFetch;

	constructor(apiConfig: CommercialApiContract, authFetch: ResilientAuthenticatedFetch) {
		this.apiConfig = apiConfig;
		this.authFetch = authFetch;
	}

	async getBulkDeductibles(policyNumber: string): Promise<bdModels.BulkDeductibleViewModel> {
		return await fetch(`${this.apiConfig.endpoint}/policy/bulkdeductibles?policyNumber=${policyNumber}`)
			.then(response => response.json());
	}

	async requestDocument(message: string, messageType: string): Promise<printModels.RequestDocumentResponse> {

		const response = await this.authFetch.fetch(`${this.apiConfig.endpoint}/messaging/topic`, {
			method: 'POST',
			headers: {
				'accept': 'text/plain',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				message: message,
				messageType: messageType
			})
		});
		const data: printModels.RequestDocumentResponse = await response.json();

		if (!response.ok) {
			const errorData = data;
			return errorData;
		}

		return data;
	}

	async putDocGenForm(
		Form: printModels.DocGenFormValues,
		PolicyNumber: string
	): Promise<HttpOperationResult<boolean>> {
		return this.authFetch
			.fetch(`${this.apiConfig.endpoint}/form/docgen/${PolicyNumber}`, {
				method: "PUT",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(Form),
			})
			.then((response) => this.handleResponse(response))
			.then((data) => this.createSuccessResult(data))
			.catch((error) => this.handleFetchError(error))
	}

	private async handleResponse(response: Response): Promise<any> {
		return response.text().then((text) => {
			const contentType = response.headers.get("Content-Type") || "";

			if (contentType.includes("application/json")) {
				return JSON.parse(text);
			}

			return text;
		}).then((data) => {
			if (!response.ok) {
				throw data;
			}
			return data;
		}
		);
	}

	private handleFetchError(error: any): HttpOperationResult<boolean> {
		if (error.response) {
			return new HttpOperationResultBuilder<boolean>()
				.addError(
					`Failed to send form. Status: ${error.response.status} ${error.response.statusText}`
				)
				.setException(error)
				.setStatus(error.response.status)
				.setData(false)
				.build();
		}

		return new HttpOperationResultBuilder<boolean>()
			.setException(error)
			.addError("Network error occurred while sending form.")
			.build();
	}

	private createSuccessResult(data: any): HttpOperationResult<boolean> {
		return new HttpOperationResultBuilder<boolean>().setData(true).build();
	}

	async updateBulkDeductibles(post: bdModels.UpdateBulkDeductiblePost): Promise<Response> {
		const response = await fetch(`${this.apiConfig.endpoint}/policy/updatedeductibles`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;'
			},
			body: JSON.stringify(post),
		});

		if (response.ok) {
			return response;
		} else {
			throw new Error(`Error: ${response.status} - ${response.statusText}`);
		}
	}

	async ratePolicy(rateRequest: ratingModels.RateRequest): Promise<Response> {
		const response = await fetch(`${this.apiConfig.endpoint}/rating/rate/policy`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;'
			},
			body: JSON.stringify(rateRequest)
		});

		if (response.ok) {
			return response;
		} else {
			throw new Error(`Error: ${response.status} - ${response.statusText}`);
		}
	}

	async searchPolicies(searchRequest: policyModels.PolicySearchRequest): Promise<policyModels.PolicySearchResponse> {
		const response = await fetch(`${this.apiConfig.endpoint}/policy/search`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(searchRequest)
		});

		if (response.ok) {
			const result: policyModels.PolicySearchResponse = await response.json();
			return result;
		}
		else {
			throw new Error(`Error: ${response.status} - ${response.statusText}`);
		}
	}

	async getRatingDetails(documentId: string): Promise<ratingModels.GetRateDetailResponse> {
		const response = await fetch(`${this.apiConfig.endpoint}/rating/ratingDetails/${documentId}`, {
			method: 'GET'
		});

		if (response.ok) {
			const result: ratingModels.GetRateDetailResponse = await response.json();
			return result;
		}
		else {
			throw new Error(`Error: ${response.status} - ${response.statusText}`);
		}
	}

	async getCoverageSummary(documentId: string): Promise<ratingModels.CoverageSummaryItem[]> {
		const response = await fetch(`${this.apiConfig.endpoint}/rating/coverageSummary/${documentId}`, {
			method: 'GET'
		});

		if (response.ok) {
			const result: ratingModels.CoverageSummaryItem[] = await response.json();

			return result;
		}
		else {
			throw new Error(`Error: ${response.status} - ${response.statusText}`);
		}
	}

	async getLossRatio(policyNumber: string): Promise<policyModels.LossRatioResponse> {
		const response = await fetch(`${this.apiConfig.endpoint}/workbench/policyloss/${policyNumber}`);

		if (response.ok) {
			const result: policyModels.LossRatioResponse = await response.json();

			return result;
		}
		else {
			throw new Error(`Error: ${response.status} - ${response.statusText}`);
		}
	}

	async getMessageClientConnection(hubName: string): Promise<configModels.GetMessageClientConnectionInfoResponse> {

		const response = await this.authFetch.fetch(`${this.apiConfig.endpoint}/config/for/messaging/${hubName}`, {
			method: 'POST'
		});

		if (response.ok) {
			const result: configModels.MessageClientConnectionInfo = await response.json();

			return new configModels.GetMessageClientConnectionInfoResponse({
				connectionInfo: result,
				success: true
			});
		}
		else {
			return new configModels.GetMessageClientConnectionInfoResponse({
				connectionInfo: null,
				message: `Error: ${response.status} - ${response.statusText}`,
				success: false
			});
		}
	}

	async getPolicyJSON(policyNumber: string, scope: PolicyScope): Promise<string> {
		const response = await fetch(`${this.apiConfig.endpoint}/policy/${policyNumber}/${scope}`, {
			method: 'GET'
		});

		if (response.ok) {
			const result: string = await response.json();
			return result;
		}
		else {
			throw new Error(`Error: ${response.status} - ${response.statusText}`);
		}
	}
}
