import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-high-emphasis opacity-60" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_system_bar = _resolveComponent("v-system-bar")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_list_item_action = _resolveComponent("v-list-item-action")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    location: "right",
    modelValue: $setup.showDrawer,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => (($setup.showDrawer) = $event)),
      $setup.handleDrawerUpdate
    ],
    temporary: "",
    class: "text-left",
    width: "400"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_system_bar, { style: {"width":"100%","top":"0","left":"0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            icon: "mdi-eye",
            class: "ms-2",
            onClick: $setup.markAllRead,
            title: "Mark all as read"
          }, null, 8 /* PROPS */, ["onClick"]),
          _createVNode(_component_v_icon, {
            icon: "mdi-delete",
            class: "ms-2",
            onClick: $setup.removeAll,
            title: "Remove all"
          }, null, 8 /* PROPS */, ["onClick"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_v_list, { class: "mt-5" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.appStore.notifications, (notification) => {
            return (_openBlock(), _createBlock(_component_v_list_item, {
              key: notification.id,
              value: notification,
              class: _normalizeClass(["py-3 text-left", { 'v-list-item--active': !notification.read }]),
              onClick: ($event: any) => ($setup.selectNotification(notification))
            }, {
              append: _withCtx(() => [
                _createVNode(_component_v_list_item_action, { class: "flex-column align-end" }, {
                  default: _withCtx(() => [
                    _createElementVNode("small", _hoisted_1, _toDisplayString($setup.formatDate(notification.created)), 1 /* TEXT */),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_container, { class: "align-end text-right ma-0 pa-0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_tooltip, { text: "Remove" }, {
                          activator: _withCtx(({ props }) => [
                            _createVNode(_component_v_icon, _mergeProps({
                              onClick: ($event: any) => ($setup.remove(notification)),
                              ref_for: true
                            }, props), {
                              default: _withCtx(() => [...(_cache[1] || (_cache[1] = [
                                _createTextVNode("mdi-delete")
                              ]))]),
                              _: 2 /* DYNAMIC */
                            }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(notification.title), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */),
                _createVNode(_component_v_list_item_subtitle, { class: "text-high-emphasis" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(notification.message), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "class", "onClick"]))
          }), 128 /* KEYED_FRAGMENT */)),
          ($setup.appStore.notifications.length === 0)
            ? (_openBlock(), _createBlock(_component_v_container, { key: 0 }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("p", { class: "text-italic" }, "You're all caught up.", -1 /* HOISTED */)
                ])),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
}