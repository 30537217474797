import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_m_btn = _resolveComponent("m-btn")!

  return (_openBlock(), _createBlock(_component_m_btn, {
    variant: "text",
    onClick: $setup.handleClick,
    class: _normalizeClass({ 'v-btn--active': $setup.showDrawer })
  }, {
    default: _withCtx(() => [
      ($setup.unreadNotifications > 0)
        ? (_openBlock(), _createBlock(_component_v_badge, {
            key: 0,
            color: "error",
            content: $setup.unreadNotifications
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { icon: "mdi-bell" })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["content"]))
        : (_openBlock(), _createBlock(_component_v_icon, {
            key: 1,
            icon: "mdi-bell"
          }))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onClick", "class"]))
}