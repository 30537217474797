import { ref } from "vue";
import { AuthService } from "@/contracts/AuthService";
import { retry, circuitBreaker, handleAll, wrap, ExponentialBackoff, ConsecutiveBreaker } from "cockatiel";
/* 
A wrapper for fetch() that adds authentication headers to the request.
*/

//
// UseAuthentication is WIP
// This will remain while we work in parallel with the new system
// 

export class AuthenticatedFetch {
	private authService: AuthService;

	constructor(authService: AuthService) {
		this.authService = authService;
	}

	async fetch(url: string, options: RequestInit = {}): Promise<Response> {
		const account = await this.authService.getActiveAccount();

		if (!account) {
			throw new Error('Unable to fetch configuration info, no logged in user');
		}

		const authHeaders = {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${account.idToken}`,
			'X-User-Id': account.username
		};

		const mergedHeaders = {
			...(options.headers || {}),
			...authHeaders,
		};

		return fetch(url, {
			...options,
			headers: mergedHeaders,
		});
	}
}

export class ResilientAuthenticatedFetch {
	private readonly authFetch: AuthenticatedFetch;
	private readonly retryWithBreaker;

	constructor(authFetch: AuthenticatedFetch) {
		this.authFetch = authFetch;

		const retryPolicy = retry(handleAll, { maxAttempts: 3, backoff: new ExponentialBackoff() });
		const circuitBreakerPolicy = circuitBreaker(handleAll, {
			halfOpenAfter: 10 * 1000,
			breaker: new ConsecutiveBreaker(5),
		});

		this.retryWithBreaker = wrap(retryPolicy, circuitBreakerPolicy);
	}

	async fetch(url: string, options: RequestInit = {}): Promise<Response> {
		return this.retryWithBreaker.execute(() => this.authFetch.fetch(url, options));
	}
}