<template>
	<m-btn variant="text" @click="handleClick" :class="{ 'v-btn--active': showDrawer }">
		<v-badge color="error" :content="unreadNotifications" v-if="unreadNotifications > 0" >
			<v-icon icon="mdi-bell" ></v-icon>
		</v-badge>
		<v-icon icon="mdi-bell" v-else></v-icon>
	</m-btn>
</template>

<script lang="ts">

import { ref, watch, computed, SetupContext, getCurrentInstance, onMounted, onUnmounted, inject, App } from 'vue';
import { useTheme } from 'vuetify';
import { useAppStore } from '@/stores/app.store';
import MBtn from '@/components/BaseButton.vue';
import { EventBus, Events } from '@/contracts/EventBus';
import { AppNotification } from '@/models/NotificationModels';

export default {
	name: 'NotificationIcon',
	components: {
		MBtn
	},
	props: {

	},

	setup(props : any, { emit } : SetupContext ) {
		const instance = getCurrentInstance();
		const appStore = useAppStore();
		const theme = useTheme();

		const eventBus = inject<EventBus>('eventBus');

		const showDrawer = ref<boolean>(false);

		const handleClick = () => {
			showDrawer.value = !showDrawer.value;

			eventBus.emit(Events.NOTIFICATION_BAR_TOGGLED, { show: showDrawer.value });
		}

		const handleAddNotification = (appNofication: AppNotification) => {
			//notificationsIconProps.notifications.value.push(appNofication);
			appStore.addNotification(appNofication);
		};

		const handleEventBarToggled = (data: { show: boolean }) => {
			showDrawer.value = data.show;
		};

		const unreadNotifications = computed(() => {
			return appStore.notifications.filter(notification => !notification.read).length;
		});

		onMounted(() => {
			eventBus.on(Events.NOTIFICATION_RECEIVED, handleAddNotification);			
			eventBus.on(Events.NOTIFICATION_BAR_TOGGLED, handleEventBarToggled);
		});

		// Clean up event listener
		onUnmounted(() => {
			eventBus.off(Events.NOTIFICATION_RECEIVED, handleAddNotification);
			eventBus.off(Events.NOTIFICATION_BAR_TOGGLED, handleEventBarToggled);
		});

		return {
			showDrawer,
			appStore,
			unreadNotifications,
			handleClick
		}
	}
}
</script>

<style scoped>
/* Add your component styles here */

</style>