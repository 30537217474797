/*
Basic contract for a message service. This service is responsible for sending and receiving messages from a message hub context.
*/

import { decodeFromUint8Array } from '../utils/Bytes';

export class MessageProperties {
	to?: string;
	sessionId?: string;
	replyToSessionId?: string;
	replyTo?: string;
	messageId?: string;
	correlationId?: string;
	contentType: string;
	timeToLive?: number; // Represented in milliseconds
	customProperties: Record<string, string> = {};

	constructor(init?: Partial<MessageProperties>) {
		Object.assign(this, init);
	}

	toJSON() {
		return {
			to: this.to ?? null,
			sessionId: this.sessionId ?? null,
			replyToSessionId: this.replyToSessionId ?? null,
			replyTo: this.replyTo ?? null,
			messageId: this.messageId ?? null,
			correlationId: this.correlationId ?? null,
			contentType: this.contentType || 'application/json',
			timeToLive: this.formatTimeToLive(this.timeToLive),
			customProperties: this.customProperties ?? {},
		};
	}

	private formatTimeToLive(ms?: number): string {
		if (!ms || ms <= 0) return '00:00:00'; // TimeSpan.Zero

		const totalSeconds = Math.floor(ms / 1000);
		const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
		const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, '0');
		const seconds = (totalSeconds % 60).toString().padStart(2, '0');

		return `${hours}:${minutes}:${seconds}`;
	}
}

export class MessageEnvelope {
	messageType: string;
	payload: Uint8Array;
	properties: MessageProperties;

	constructor(
		messageType: string,
		payload: Uint8Array,
		properties: MessageProperties
	) {
		if (!messageType || payload == null) {
			throw new Error('Null argument exception: messageType and payload are required.');
		}

		this.messageType = messageType;
		this.payload = payload;
		this.properties = properties;
	}

	static fromObject<T>(messageType: string, payload: T, messageProperties: MessageProperties): MessageEnvelope {
		const json = JSON.stringify(payload);
		const bytes = new TextEncoder().encode(json);
		return new MessageEnvelope(messageType, bytes, messageProperties);
	}

	static fromJSON(obj: any): MessageEnvelope {
		if (!obj?.messageType || !obj?.payload || !obj?.properties) {
			throw new Error("Invalid MessageEnvelope format.");
		}

		const payloadBytes = new Uint8Array(
			[...atob(obj.payload)].map(char => char.charCodeAt(0))
		);

		const props = new MessageProperties(obj.properties);

		return new MessageEnvelope(
			obj.messageType,
			payloadBytes,
			props
		);
	}

	getDecodedPayload<T>(): T {
		return decodeFromUint8Array(this.payload) as T;
	}
}

export interface MessageService {
	start(hubName: string): Promise<void>;
	onMessage<T>(methodName: string, callback: (payload: T, envelope: MessageEnvelope) => void, messageProperties?: MessageProperties): void;
	offMessage(methodName: string, callback: () => void): void;
	sendMessage<T>(methodName: string, data: T, messageProperties?: MessageProperties): Promise<void>;
	stop(): Promise<void>;
}
