import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "templateListControlContainer",
  class: "templatelist-control-container"
}
const _hoisted_2 = {
  ref: "dataCaptureControlContainer",
  class: "datacapture-control-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WaitDialog = _resolveComponent("WaitDialog")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "ghostDraftDataCapturePOC" }, {
    default: _withCtx(() => [
      _createVNode(_component_WaitDialog, {
        isActive: $setup.isWaiting,
        title: "What are you waiting for?"
      }, null, 8 /* PROPS */, ["isActive"]),
      _createVNode(_component_v_row, { class: "ghostDraftControls" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "2",
            md: "2",
            sm: "3"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, null, 512 /* NEED_PATCH */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_col, {
            cols: "10",
            md: "10",
            sm: "9"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, null, 512 /* NEED_PATCH */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}