import { ConfigService } from "@/contracts/ConfigService";
import { ApplicationInsights, SeverityLevel, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { MessageService, MessageEnvelope } from "@/contracts/MessageService";
import { isUint8Array } from "@/utils/Bytes";

export interface DocsGeneratedMessage {
	PolicyNumber: string;
}

export class SignalRMessageService implements MessageService {
	private connection: signalR.HubConnection | null = null;
	private configService: ConfigService;
	private telemetry: ApplicationInsights;

	constructor(configService: ConfigService, telemetry: ApplicationInsights) {
		this.configService = configService;
		this.telemetry = telemetry;
	}

	// Start the SignalR hub connection
	public async start(hubName: string): Promise<void> {
		if (!this.connection) {
			const connectionResult = await this.configService.getMessageClientConnection(hubName);

			if (connectionResult.success) {
				this.connection = new HubConnectionBuilder()
					.withUrl(connectionResult.connectionInfo.url, {
						accessTokenFactory: () => connectionResult.connectionInfo.accessToken
					}) // Replace with your Azure SignalR endpoint
					.configureLogging(LogLevel.Warning)
					.build();

				try {
					await this.connection.start();
					console.log("SignalR Connected.");
				} catch (err) {
					const exceptionTelemetry: IExceptionTelemetry = {
						exception: new Error("Error while starting SignalR connection"),
						severityLevel: SeverityLevel.Error,
						properties: { connectionResult: connectionResult }
					};

					this.telemetry.trackException(exceptionTelemetry);

					setTimeout(() => this.start(hubName), 5000); // Retry on failure
				}
			}
			else {
				const exceptionTelemetry: IExceptionTelemetry = {
					exception: new Error("Error while negotiating for SignalR config"),
					severityLevel: SeverityLevel.Error,
					properties: { connectionResult: connectionResult }
				};

				this.telemetry.trackException(exceptionTelemetry);

				setTimeout(() => this.start(hubName), 5000); // Retry on failure
			}
		}
	}

	public onMessage<T>(
		methodName: string,
		callback: (payload: T, envelope: MessageEnvelope) => void
	): void {
		if (!this.connection) return;

		this.connection.on(methodName, (raw: Uint8Array | string | object) => {
			try {
				let envelopeData: any;

				if (isUint8Array(raw)) {
					const json = new TextDecoder().decode(raw);
					envelopeData = JSON.parse(json);
				} else if (typeof raw === 'string') {
					envelopeData = JSON.parse(raw);
				} else {
					envelopeData = raw;
				}

				const envelope = MessageEnvelope.fromJSON(envelopeData);
				const payload = envelope.getDecodedPayload() as T;

				callback(payload, envelope);

			} catch (error) {
				console.error(`Failed to process message for '${methodName}'`, error);
				this.telemetry?.trackException({
					exception: error instanceof Error ? error : new Error('MessageEnvelope deserialization failed'),
					properties: { source: 'SignalRMessageService.onMessage', methodName }
				});
			}
		});
	}

	// Remove a message listener
	public offMessage(methodName: string, callback: () => void): void {
		if (this.connection) {
			this.connection.off(methodName, callback);
		}
	}

	// Send a message to the server
	public async sendMessage<T>(methodName: string, data: T): Promise<void> {
		if (this.connection) {
			try {
				await this.connection.invoke(methodName, data);
				console.log(`Message sent to ${methodName}:`, data);
			} catch (err) {
				console.error("Error while sending message:", err);
			}
		}
	}

	// Stop the SignalR connection
	public async stop(): Promise<void> {
		if (this.connection) {
			await this.connection.stop();
			console.log("SignalR Disconnected.");
			this.connection = null;
		}
	}
}
