<template>
	<v-container class="ghostDraftDataCapturePOC">
		<WaitDialog :isActive="isWaiting" title="What are you waiting for?" />

		<v-row class="ghostDraftControls">
			<v-col cols="2" md="2" sm="3">
				<div ref="templateListControlContainer" class="templatelist-control-container"></div>
			</v-col>
			<v-col cols="10" md="10" sm="9">
				<div ref="dataCaptureControlContainer" class="datacapture-control-container"></div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script lang="ts">
import { ref, onMounted, inject } from 'vue';
import WaitDialog from '@/components/WaitDialog.vue';
import MBtn from '@/components/BaseButton.vue';
import { Tell } from '@/services/Tell';
import { SignalRMessageService, DocsGeneratedMessage } from '@/services/SignalRMessageService';
import { MessageService } from '@/contracts/MessageService';
import { EventBus, Events } from '@/contracts/EventBus';
import { AppNotification } from '@/models/NotificationModels';
import { GhostDraftService } from '@/services/GhostDraftService';
import * as GhostDraftDataCapture from '@/assets/GhostDraftDataCapture-3.0';

export default {
	//name doesn't match the file name, but we're doing it like this for uri simplicity on Entra config
	//and for clarity on the vue app
	name: 'GhostDraftDataCapturePOC',
	route: {
		path: '/ghost-dc-poc',
		meta: {
			requiresAuth: true,
			title: 'GhostDraft Data Capture POC'
		}
	},
	components: {
		WaitDialog,
		//MBtn
	},
	props: {
		//these properties are exposed to consumers of this component
	},
	setup() {
		const dialog = ref<boolean>(false);
		const isWaiting = ref<boolean>(false);
		const messageService = inject('messageService') as MessageService;
		const tell = inject('tell') as Tell;
		const eventBus = inject('eventBus') as EventBus;
		const templateListControlContainer = ref<HTMLDivElement | null>(null);
		const dataCaptureControlContainer = ref<HTMLDivElement | null>(null);
		const templateListOneTimeUseAuthToken = ref<string>("");
		const dataCaptureOneTimeUseAuthToken = ref<string>("");

		async function CreateTemplateListUX(): Promise<void> {
			// list of forms
			templateListOneTimeUseAuthToken.value = await GhostDraftService.getDatCaptureAuthToken();

			await GhostDraftDataCapture.createTemplateCatalog({
				targetDiv: templateListControlContainer.value,
				token: templateListOneTimeUseAuthToken.value,
				baseUrl: undefined, // documentation says "(Reserved for future use) Do not provide a value."
				selectTemplateCallBack: CreateDataCaptureUX,
				catalog: undefined,
				// move the settings below to config
				company: "moedev",
				documentService: 'ITDevelopment',
				packageName: 'ISO Commercial Auto Project',
				packageVersion: '2410.0'
			});
		}

		async function CreateDataCaptureUX(args: GhostDraftDataCapture.IDataCaptureArguments) {
			dataCaptureOneTimeUseAuthToken.value = await GhostDraftService.getDatCaptureAuthToken();

			await GhostDraftDataCapture.createDataCapture({
				targetDiv: dataCaptureControlContainer.value,
				token: dataCaptureOneTimeUseAuthToken.value,
				baseUrl: undefined, // documentation says "(Reserved for future use) Do not provide a value."
				template: args.template,
				dialogDesign: "",
				options: GhostDraftDataCapture.DataCaptureDisplayFlags.showTopBar
					| GhostDraftDataCapture.DataCaptureDisplayFlags.showPreview
					| GhostDraftDataCapture.DataCaptureDisplayFlags.showBottomBar
					| GhostDraftDataCapture.DataCaptureDisplayFlags.showMenu
					| GhostDraftDataCapture.DataCaptureDisplayFlags.showSubmit
					| GhostDraftDataCapture.DataCaptureDisplayFlags.showNextPreviousButtons
					| GhostDraftDataCapture.DataCaptureDisplayFlags.showLeftPane
					| GhostDraftDataCapture.DataCaptureDisplayFlags.showLogo
					| GhostDraftDataCapture.DataCaptureDisplayFlags.showSearch
					| GhostDraftDataCapture.DataCaptureDisplayFlags.showToolbar,
				// move the settings below to config
				company: "moedev",
				documentService: 'ITDevelopment',
				packageName: 'ISO Commercial Auto Project',
				packageVersion: '2410.0'
			});

		}

		function applyIframeStyles() {
			const container_1 = templateListControlContainer.value;
			const container_2 = dataCaptureControlContainer.value;

			const containers = [container_1, container_2];
			containers.forEach(container => {
				if (!container) return;
				const iframes = container.querySelectorAll('iframe');
				iframes.forEach(iframe => {
					iframe.setAttribute('width', '100%');
					iframe.setAttribute('height', '100%');
				});
			});
		}

		function observeIframes(container: HTMLElement | null) {
			if (!container) return;

			const observer = new MutationObserver(() => {
				applyIframeStyles();
			});

			observer.observe(container, {
				childList: true,
				subtree: true
			});
		}

		onMounted(async () => {
			await CreateTemplateListUX();
			applyIframeStyles();
			observeIframes(dataCaptureControlContainer.value);
		});

		return {
			dialog,
			isWaiting,
			tell,
			templateListControlContainer,
			dataCaptureControlContainer,
		}
	}
}
</script>
<style scoped lang="scss">
v-container {
	height: 100%;
}

.datacapture-control-container {
	height: 100%;
	width: 100%;
	display: flex;

	>iframe.tc_frame {
		height: 100% !important;
		width: 100% !important;
		max-width: 100% !important;
		display: flex;
	}
}

.templatelist-control-container {
	height: 100%;
	width: 100%;
	display: flex;

	>iframe.tc_frame {
		height: 100% !important;
		width: 100% !important;
		display: flex;
	}
}

button {
	margin-right: var(--v-spacing-default);
}

/* frame that gets added inside div by GhostDraft DataCaptureWithTemplateSelection control */
#dc-div iframe {
	height: 100%;
}

.ghostDraftControls {
	height: 100%;
}

.ghostDraftDataCapturePOC {
	height: 100%;
}

</style>