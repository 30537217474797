import { resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WaitDialog = _resolveComponent("WaitDialog")!
  const _component_v_stepper_item = _resolveComponent("v-stepper-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_stepper_header = _resolveComponent("v-stepper-header")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_virtual_scroll = _resolveComponent("v-virtual-scroll")!
  const _component_v_stepper_window_item = _resolveComponent("v-stepper-window-item")!
  const _component_v_stepper_window = _resolveComponent("v-stepper-window")!
  const _component_v_stepper_actions = _resolveComponent("v-stepper-actions")!
  const _component_v_stepper = _resolveComponent("v-stepper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_WaitDialog, {
      isActive: $setup.isWaiting,
      title: "Working"
    }, null, 8 /* PROPS */, ["isActive"]),
    ($setup.htmlForms.length)
      ? (_openBlock(), _createBlock(_component_v_stepper, {
          key: 0,
          mobile: "",
          modelValue: $setup.currentStep,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.currentStep) = $event)),
          width: "95%",
          editable: ""
        }, {
          default: _withCtx(({ }) => [
            _createCommentVNode(" Stepper Header "),
            _createVNode(_component_v_stepper_header, { class: "bg-primary" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.htmlForms, (form, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: `step-${index}`
                  }, [
                    _createVNode(_component_v_stepper_item, {
                      color: "secondary",
                      complete: $setup.currentStep > index + 1,
                      step: index + 1,
                      value: index + 1
                    }, {
                      default: _withCtx(() => [
                        _createCommentVNode(" Step {{ index + 1 }} ")
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["complete", "step", "value"]),
                    (index < $setup.htmlForms.length - 1)
                      ? (_openBlock(), _createBlock(_component_v_divider, {
                          key: `divider-${index}`
                        }))
                      : _createCommentVNode("v-if", true)
                  ], 64 /* STABLE_FRAGMENT */))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }),
            _createCommentVNode(" Stepper Window "),
            _createVNode(_component_v_stepper_window, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.htmlForms, (form, index) => {
                  return (_openBlock(), _createBlock(_component_v_stepper_window_item, {
                    key: `content-${index}`,
                    value: index + 1
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "d-flex justify-space-between" }, {
                        default: _withCtx(() => [
                          _createCommentVNode(" Left Side: Forms to be Collected "),
                          _createVNode(_component_v_col, {
                            cols: "6",
                            class: "d-flex align-start justify-start",
                            ref_for: true,
                            ref: "leftCol",
                            style: {"overflow":"auto","max-height":"900px"}
                          }, {
                            default: _withCtx(() => [
                              _createCommentVNode(" color=\"primaryMoe\"> "),
                              _createVNode(_component_v_form, {
                                ref_for: true,
                                ref: "formRef",
                                class: "w-75 my-10 mx-auto"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.groupedOccurrences, (row, rowIndex) => {
                                    return (_openBlock(), _createBlock(_component_v_row, {
                                      key: rowIndex,
                                      class: "d-flex justify-start"
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (occurrence, index) => {
                                          return (_openBlock(), _createBlock(_component_v_col, {
                                            key: index,
                                            cols: $setup.findColSize(occurrence.maxLength)
                                          }, {
                                            default: _withCtx(() => [
                                              (occurrence.textBox === false)
                                                ? (_openBlock(), _createBlock(_component_v_text_field, {
                                                    key: 0,
                                                    modelValue: occurrence.inputValue,
                                                    "onUpdate:modelValue": ($event: any) => ((occurrence.inputValue) = $event),
                                                    label: $setup.getLabel(occurrence.titleName),
                                                    maxlength: occurrence.numberOfInputLines * (occurrence.maxLength),
                                                    onFocus: ($event: any) => ($setup.handleFocus(occurrence)),
                                                    variant: "solo-filled"
                                                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label", "maxlength", "onFocus"]))
                                                : (_openBlock(), _createBlock(_component_v_textarea, {
                                                    key: 1,
                                                    label: $setup.getLabel(occurrence.titleName),
                                                    rows: occurrence.numberOfInputLines,
                                                    maxlength: occurrence.numberOfInputLines * (occurrence.maxLength),
                                                    modelValue: occurrence.inputValue,
                                                    "onUpdate:modelValue": ($event: any) => ((occurrence.inputValue) = $event),
                                                    onInput: ($event: any) => ($setup.handleTextareaInput(occurrence)),
                                                    onFocus: $setup.handleFocus,
                                                    variant: "solo-filled"
                                                  }, null, 8 /* PROPS */, ["label", "rows", "maxlength", "modelValue", "onUpdate:modelValue", "onInput", "onFocus"]))
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["cols"]))
                                        }), 128 /* KEYED_FRAGMENT */))
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1024 /* DYNAMIC_SLOTS */))
                                  }), 128 /* KEYED_FRAGMENT */))
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1536 /* NEED_PATCH, DYNAMIC_SLOTS */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1536 /* NEED_PATCH, DYNAMIC_SLOTS */),
                          _createCommentVNode(" Right Side: HTML Preview "),
                          _createVNode(_component_v_col, {
                            cols: "6",
                            class: "h-100"
                          }, {
                            default: _withCtx(() => [
                              _createCommentVNode(" read only class added to prevent user modifying html preview "),
                              _createVNode(_component_v_virtual_scroll, {
                                items: [$setup.htmlForms[$setup.currentStep - 1]],
                                height: "900px"
                              }, {
                                default: _withCtx(({ item }) => [
                                  _createElementVNode("div", {
                                    innerHTML: item,
                                    class: "read-only-content"
                                  }, null, 8 /* PROPS */, _hoisted_1)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["items"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }),
            _createCommentVNode(" Stepper Actions "),
            _createVNode(_component_v_stepper_actions, {
              "onClick:prev": $setup.prevStep,
              "onClick:next": $setup.nextStep
            }, null, 8 /* PROPS */, ["onClick:prev", "onClick:next"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"]))
      : _createCommentVNode("v-if", true),
    ($setup.pdfContent)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 1,
          src: $setup.pdfContent,
          width: "80%",
          height: "100%"
        }, null, 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}