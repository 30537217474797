<template>
	<WaitDialog :isActive="isWaiting" title="Working" />

	<v-stepper mobile v-if="htmlForms.length" v-model="currentStep" width="95%" editable>
		<template v-slot:default="{ }">
			<!-- Stepper Header -->
			<v-stepper-header class="bg-primary">
				<template v-for="(form, index) in htmlForms" :key="`step-${index}`">
					<v-stepper-item color="secondary" :complete="currentStep > index + 1" :step="index + 1"
						:value="index + 1">
						<!-- Step {{ index + 1 }} -->
					</v-stepper-item>

					<v-divider v-if="index < htmlForms.length - 1" :key="`divider-${index}`"></v-divider>
				</template>
			</v-stepper-header>

			<!-- Stepper Window -->
			<v-stepper-window>
				<v-stepper-window-item v-for="(form, index) in htmlForms" :key="`content-${index}`" :value="index + 1">
					<v-row class="d-flex justify-space-between">
						<!-- Left Side: Forms to be Collected -->
						<v-col cols="6" class="d-flex align-start justify-start" ref="leftCol" style="overflow: auto; max-height: 900px;">
							<!-- color="primaryMoe"> -->
							<v-form ref="formRef" class="w-75 my-10 mx-auto">
								<template v-for="(row, rowIndex) in groupedOccurrences" :key="rowIndex">
									<v-row class="d-flex justify-start">
										<v-col v-for="(occurrence, index) in row" :key="index"
											:cols="findColSize(occurrence.maxLength)">
											<v-text-field v-if="occurrence.textBox === false"
												v-model="occurrence.inputValue" :label="getLabel(occurrence.titleName)"
												:maxlength="occurrence.numberOfInputLines * (occurrence.maxLength)"
												@focus="handleFocus(occurrence)" variant="solo-filled"></v-text-field>
											<v-textarea v-else :label="getLabel(occurrence.titleName)"
												:rows="occurrence.numberOfInputLines"
												:maxlength="occurrence.numberOfInputLines * (occurrence.maxLength)"
												v-model="occurrence.inputValue" @input="handleTextareaInput(occurrence)"
												@focus="handleFocus" variant="solo-filled"></v-textarea>
										</v-col>
									</v-row>
								</template>
							</v-form>

						</v-col>

						<!-- Right Side: HTML Preview -->
						<v-col cols="6" class="h-100">
							<!-- read only class added to prevent user modifying html preview -->
							<v-virtual-scroll :items="[htmlForms[currentStep - 1]]" height="900px">
								<template v-slot="{ item }">
									<div v-html="item" class="read-only-content"></div>
								</template>
							</v-virtual-scroll>
						</v-col>
					</v-row>
				</v-stepper-window-item>
			</v-stepper-window>

			<!-- Stepper Actions -->
			<v-stepper-actions @click:prev="prevStep" @click:next="nextStep">
			</v-stepper-actions>
		</template>
	</v-stepper>

	<iframe v-if="pdfContent" :src="pdfContent" width="80%" height="100%"></iframe>
</template>

<script lang="ts">

import { ref, onMounted, onBeforeUnmount, watch, inject, computed } from 'vue';
import { useRoute } from 'vue-router';
import wipFormIdentifiers from '@/json/wipFormIdentifiers.json';
import MBtn from '@/components/BaseButton.vue';
import WaitDialog from '@/components/WaitDialog.vue';
import { DocGenDocumentSource } from '@/models/PrintDocumentModel';
import { usePrintDocumentsLogic } from '../composables/PrintDocuments/UsePrintDocumentsLogic';

export default {
	name: 'PrintDocuments',
	route: {
		path: '/print-documents',
		meta: {
			requiresAuth: true,
			title: 'WIP Form'
		}
	},
	components: {
		// MBtn,
		WaitDialog
	},
	setup() {
		const route = useRoute();
		const policyNumber = ref<string | null>(route.query.policyNumber as string || null);
		const coveragePartsAffected = ref('');
		const formRef = ref<string | null>(null);
		const htmlContent = ref(null);
		const pdfContent = ref<string>(null);
		const htmlForms = ref<string[]>([]);
		const currentStep = ref(1); // Tracks the current step in the stepper
		const matchedInputs = ref([]);
		const printDocument = ref<DocGenDocumentSource>(null);
		const htmlInputs = ref([]);
		const infiniteScrollHeight = ref('800px');
		const isLocallyBusy = ref(true);
		const isWaiting = computed(() => isLocallyBusy.value || isServiceBusy.value);
		const occurrences = ref<{
			titleName: string;
			className: string;
			numberOfInputLines: number;
			maxLength: number | null;
			size: string | null;
			name: string | null;
			inputValue: string | null;
			groupName: string[];
			textBox: boolean;
		}[]>([]);

		const {
			onPageLoad,
			registerHandlers,
			unregisterHandlers,
			submitForm,
			loading: isServiceBusy,
		} = usePrintDocumentsLogic(policyNumber, occurrences, htmlContent, pdfContent);

		const load = ({ done }: { done: (status: any) => void }) => {
			setTimeout(() => {
				if (htmlContent.value !== null) {
					done('ok');
				} else {
					done('empty');
				}
			}, 1000);
		};

		const findForms = () => {
			if (!htmlContent.value) return;

			const parser = new DOMParser();
			const doc = parser.parseFromString(htmlContent.value, 'text/html');
			// unique page identifier
			const uniquePages = 'div[style*="BORDER-TOP: black 3px double;"][style*="HEIGHT: 11in;"]';

			// Find all divs with the unique page identifier
			const pages = doc.querySelectorAll(uniquePages);

			// Save the content of each page into the array only if it contains input elements
			htmlForms.value = Array.from(pages)
				.map((page, index) => {
					// Clone the original document to preserve other HTML content
					const clonedDoc = parser.parseFromString(htmlContent.value, 'text/html');

					// Remove all other occurrences of the unique div identifier except the current one
					const allPages = clonedDoc.querySelectorAll(uniquePages);
					allPages.forEach((otherPage, otherIndex) => {
						if (otherIndex !== index) {
							otherPage.remove(); // Remove all other occurrences
						}
					});

					// Check if the current page contains input elements
					const currentPage = clonedDoc.querySelector(uniquePages);
					const inputs = currentPage?.querySelectorAll('input');
					if (inputs && inputs.length > 0) {
						// Return the modified HTML for the current page if inputs are present
						return clonedDoc.documentElement.outerHTML;
					}

					// Return null if no inputs are present
					return null;
				})
				.filter((page) => page !== null); // Remove null entries
		};

		const getLabel = (name: string) => {

			const match = wipFormIdentifiers.find((identifier) => identifier.identifier === name);
			return match ? match.headerName : name;
		};

		const parseHtmlContent = (content: any) => {
			// Parse the HTML content and modify the <body> tag
			const parser = new DOMParser();
			const doc = parser.parseFromString(content, 'text/html');
			const head = doc.querySelector('head');
			if (head) {
				const style = document.createElement('style');
				style.textContent = '* { text-wrap-mode: nowrap !important; }';
				head.appendChild(style);

				// Remove link tags for POINTLayout.css and common.css
				const linksToRemove = head.querySelectorAll('link[href*="POINTLayout.css"], link[href*="common.css"]');
				linksToRemove.forEach(link => link.remove());
			}

			// Search for input elements with specified attributes
			const inputs = doc.querySelectorAll('input[title^="COV"]');
			const inputValues: any[] = [];
			inputs.forEach((input, index) => {
				const newValue = (input as HTMLInputElement).value;
				inputValues.push(newValue);
				// Update the input element with the corresponding value from matchedInputs
				input.setAttribute('value', newValue);
			});
			matchedInputs.value = inputValues;

			return doc.documentElement.outerHTML;
		};

		// Function to find input occurrences for the current step
		const findInputOccurrencesForStep = (index: number) => {
			if (htmlForms.value[index]) {
				const htmlString = htmlForms.value[index];
				findInputOccurrences(htmlString);
			}
		};

		const findInputOccurrences = (htmlString: string) => {
			const parser = new DOMParser();
			const doc = parser.parseFromString(htmlString, 'text/html');
			const inputs = doc.querySelectorAll('input');
			htmlInputs.value = Array.from(inputs);
			occurrences.value = [];

			inputs.forEach((input) => {
				const title = input.getAttribute('title');
				const className = input.getAttribute('class');
				const maxLength = input.getAttribute('maxLength');
				const size = input.getAttribute('size');
				const name = input.getAttribute('name');

				if (title && className) {
					const firstChars = title.match(/^[a-zA-Z\s-#]+/);
					const nextChar = title[firstChars[0].length];
					const isNextCharNumber = /^\d$/.test(nextChar);

					if (firstChars && isNextCharNumber) {
						const existingOccurrence = occurrences.value.find(
							(occurrence) =>
								occurrence.className === className &&
								occurrence.titleName.startsWith(firstChars[0])
						);

						if (existingOccurrence) {
							existingOccurrence.numberOfInputLines += 1;
							existingOccurrence.groupName.push(title);
						} else {
							occurrences.value.push({
								titleName: title,
								className: className,
								numberOfInputLines: 1,
								maxLength: parseInt(maxLength),
								size: size,
								name: name,
								inputValue: null,
								groupName: [title],
								textBox: false,
							});
						}
					} else if (/^[a-zA-Z\s-]+$/.test(title)) {
						const existingOccurrence = occurrences.value.find(
							(occurrence) =>
								occurrence.className === className && occurrence.titleName === title
						);

						if (!existingOccurrence) {
							occurrences.value.push({
								titleName: title,
								className: className,
								numberOfInputLines: 1,
								maxLength: parseInt(maxLength),
								size: size,
								name: name,
								inputValue: null,
								groupName: [title],
								textBox: false,
							});
						}
					}
				}
			});

			// Check for matches in wipFormIdentifiers.json
			const updatedOccurrences: {
				titleName: string;
				className: string;
				numberOfInputLines: number;
				maxLength: number | null;
				size: string | null;
				name: string | null;
				inputValue: string | null;
				groupName: string[];
				textBox: boolean;
			}[] = [];

			occurrences.value.forEach((occurrence) => {
				const match = wipFormIdentifiers.find(
					(identifier) => identifier.identifier === occurrence.titleName
				);

				if (match && match.numberOfColumns) {
					// Treat as a single occurrence if numberOfColumns is present
					const regex = /^([a-zA-Z\s-#]*)(\d+)?$/;
					const titleMatch = occurrence.titleName.match(regex);

					if (titleMatch) {
						const letterPart = titleMatch[1];
						const baseNumber = parseInt(titleMatch[2]);
						const groupName = [];

						for (let i = 0; i < match.numberOfColumns; i++) {
							const nextTitle = `${letterPart}${baseNumber + i}`;
							const nextInput = Array.from(inputs).find(
								(input) => input.getAttribute('title') === nextTitle
							);

							if (nextInput) {
								groupName.push(nextTitle);
							}
						}

						updatedOccurrences.push({
							titleName: occurrence.titleName,
							className: occurrence.className,
							numberOfInputLines: occurrence.numberOfInputLines,
							maxLength: occurrence.maxLength,
							size: occurrence.size,
							name: occurrence.name,
							inputValue: null,
							groupName: groupName, // Ensure it's a tuple with one element
							textBox: true, // Set textBox to true if numberOfColumns is present
						});
					}
				} else {
					// Split groupName into individual occurrences if numberOfColumns is not present
					occurrence.groupName.forEach((groupName) => {
						updatedOccurrences.push({
							titleName: groupName,
							className: occurrence.className,
							numberOfInputLines: 1,
							maxLength: occurrence.maxLength,
							size: occurrence.size,
							name: occurrence.name,
							inputValue: null,
							groupName: [groupName],
							textBox: false, // Default to false for individual occurrences
						});
					});
				}
			});
			occurrences.value = updatedOccurrences;

			return occurrences.value.length;
		};

		const findColSize = (maxSize: number) => {
			if (maxSize <= 3) {
				return 6;
			} else if (maxSize <= 11) {
				return 6;
			} else {
				return 12;
			}
		};

		const groupedOccurrences = computed(() => {
			const rows = [];
			let currentRow: {
				inputValue: any; titleName: string; className: string; numberOfInputLines: number; maxLength: number | null; size: string | null; name: string | null; textBox: boolean; groupName: string[];
			}[] = [];
			let currentRowLength = 0;

			occurrences.value.forEach((occurrence) => {
				const colSize = findColSize(occurrence.maxLength);
				if (currentRowLength + colSize > 12) {
					rows.push(currentRow);
					currentRow = [];
					currentRowLength = 0;
				}
				currentRow.push(occurrence);
				currentRowLength += colSize;
			});

			if (currentRow.length > 0) {
				rows.push(currentRow);
			}

			return rows;
		});

		onMounted(async () => {
			(window as any).$vueComponentInstance = {
				htmlContent,
				pdfContent,
			};

			isLocallyBusy.value = true;

			await onPageLoad();

			registerHandlers();

			await initTestHook();
			findForms();

			// Ensure htmlForms is populated before calling findInputOccurrencesForStep
			if (htmlForms.value.length > 0) {
				findInputOccurrencesForStep(currentStep.value - 1);
			}

			initInfiniteScrollHeight();

			isLocallyBusy.value = false;
		});

		onBeforeUnmount(() => {
			unregisterHandlers();
		});

		// Watch the coveragePartsAffected value and update matchedInputs

		watch(
			occurrences,
			(newOccurrences) => {
				const chunks: any[] = [];
				newOccurrences.forEach((occurrence) => {
					const maxLength = occurrence.maxLength || 70; // Default to 70 if maxLength is not defined
					const inputValue = occurrence.inputValue || '';
					for (let i = 0; i < inputValue.length; i += maxLength) {
						chunks.push(inputValue.slice(i, i + maxLength));
					}
				});

				matchedInputs.value = chunks;

				// Update the specific form in htmlForms[currentStep - 1]
				if (currentStep.value > 0 && htmlForms.value[currentStep.value - 1]) {
					const parser = new DOMParser();
					const doc = parser.parseFromString(htmlForms.value[currentStep.value - 1], 'text/html');

					newOccurrences.forEach((occurrence) => {
						let remainingValue = occurrence.inputValue || '';

						if (occurrence.groupName.length > 1) {
							occurrence.groupName.forEach((groupName) => {
								const input = doc.querySelector(`input[title="${groupName}"]`);
								if (input) {
									const maxLength = occurrence.maxLength || 70;
									const valueToSet = remainingValue.slice(0, maxLength);
									input.setAttribute('value', valueToSet);
									remainingValue = remainingValue.slice(maxLength);
								}
							});
						} else {
							const input = doc.querySelector(`input[title="${occurrence.titleName}"]`);
							if (input) {
								input.setAttribute('value', remainingValue);
							}
						}
					});

					// Update the specific form in htmlForms
					htmlForms.value[currentStep.value - 1] = doc.documentElement.outerHTML;
				}
			},
			{ deep: true }
		);

		watch(
			htmlContent,
			(newContent) => {
				if (newContent) {
					findForms();
				}
			},
			{ immediate: true }
		)

		watch(currentStep, (newStep) => {
			if (htmlForms.value.length > 0) {
				findInputOccurrencesForStep(newStep - 1); // Update occurrences for the current step
			} else {
				console.log('html content not ready');
			}
		});

		const handleTextareaInput = (occurrence: { maxLength: number; inputValue: string; }) => {
			const maxLength = occurrence.maxLength || 70;
			let inputValue = occurrence.inputValue || '';
			let formattedValue = '';
			let nextNewLinePosition = maxLength;

			if (inputValue.length > nextNewLinePosition) {
				formattedValue = inputValue.match(new RegExp(`.{1,${maxLength}}`, 'g')).join('\n');
				nextNewLinePosition += maxLength;
			} else {
				formattedValue = inputValue;
			}

			occurrence.inputValue = formattedValue;
		};

		const handleFocus = (occurrence: { titleName: string; }) => {
			const input = document.querySelector(`input[title="${occurrence.titleName}"]`);
			if (input) {
				input.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		};

		const initInfiniteScrollHeight = () => {
			// Calculate the height of the form and set the height of the infinite scroll
			const formElement = document.querySelector('form');
			if (formElement) {
				infiniteScrollHeight.value = `${formElement.clientHeight + 200}px`;
			}
		};

		const initTestHook = async () => {
			// Testing material for UI form collection

			isLocallyBusy.value = true;

			const testPrintDocument = async () => {
				const iframeSrc = '/form-sample.html';
				const response = await fetch(iframeSrc);
				const content = await response.text();
				return parseHtmlContent(content);
			};
			htmlContent.value = await testPrintDocument();
			findInputOccurrencesForStep(currentStep.value - 1);
		};

		const nextStep = () => {
			currentStep.value += 1;
		};

		const prevStep = () => {
			currentStep.value -= 1;
		};

		return {
			// Your reactive properties and methods here
			htmlContent,
			coveragePartsAffected,
			printDocument,
			pdfContent,
			parseHtmlContent,
			findInputOccurrences,
			occurrences,
			findColSize,
			groupedOccurrences,
			handleTextareaInput,
			handleFocus,
			infiniteScrollHeight,
			load,
			getLabel,
			submitForm,
			formRef,
			isWaiting,
			htmlForms,
			currentStep,
			findInputOccurrencesForStep,
			nextStep,
			prevStep,
		};
	},
};
</script>

<style scoped>
/* Your styles here */
.read-only-content {
	pointer-events: none;
	user-select: none;
}

.bg-sheet {
	color: rgb(var(--v-theme-primary));
}
</style>