import { AppNotification } from '@/models/NotificationModels';
import { defineStore } from 'pinia';

interface AppState {
	theme: string | null;
	notifications: AppNotification[];
}

export const useAppStore = defineStore({
	id: 'appStore',
	state: (): AppState => {
		return {
			theme: null,
			notifications: [] as AppNotification[],
		};
	},
	// this tells the store to persist to local storage
	persist: true,
	getters: {
		// getters are reactive
		getTheme: (state): string | null => state.theme,
		//getNotifications: (state): AppNotification[] => state.notifications,
	},
	actions: {
		setTheme(theme: string): void {
			this.theme = theme;
		},

		addNotification(notification: AppNotification): void {
			this.notifications.unshift(notification);
		}
	}
});