export interface AppError {
    userMessage: string;
    logMessage?: string;
    telemetryMessage?: string;
    exception?: unknown;
}

const general = 'We encountered an issue processing your request. Please try again or contact support.';

function stringifyError(e: unknown): string {
    if (e instanceof Error) {
        return `${e.name}: ${e.message}\n${e.stack ?? ''}`;
    }
    if (typeof e === 'string') {
        return e;
    }
    try {
        return JSON.stringify(e);
    } catch {
        return String(e);
    }
}

function withErrorLog(message: string, e?: unknown): string {
    if (!e) return message;
    return `${message} | Error: ${stringifyError(e)}`;
}

export const ErrorScenarios = {
    generalFailure: (e?: unknown): AppError => ({
        userMessage: general,
        logMessage: withErrorLog('General failure occurred', e),
        telemetryMessage: 'Internal Error',
        exception: e
    }),

    docGenStarted: (e?: unknown): AppError => ({
        userMessage: 'There was an issue processing the document content. Please try again.',
        logMessage: withErrorLog('DocGen processing started', e),
        telemetryMessage: 'DocGen: handleDocsGetDocGen failed',
        exception: e
    }),

    docGenFailure: (e?: unknown): AppError => ({
        userMessage: 'There was an issue processing the document content. Please try again.',
        logMessage: withErrorLog('DocGen processing failed', e),
        telemetryMessage: 'DocGen: handleDocsGetDocGen failed',
        exception: e
    }),

    formSubmitFailure: (e?: unknown): AppError => ({
        userMessage: 'An error occurred while submitting the form. Please try again.',
        logMessage: withErrorLog('Form submission failed', e),
        telemetryMessage: 'Form submit failed in putDocGenForm',
        exception: e
    }),
};
