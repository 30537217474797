export interface EventBus {
	on(event: string, callback: (...args: any[]) => void): void;
	once(event: string, callback: (...args: any[]) => void): void;
	off(event: string, callback: (...args: any[]) => void): void;
	emit(event: string, ...args: any[]): void;
}

export const Events =
{
	// Define public constants for event strings
	NOTIFICATION_BAR_TOGGLED: 'notification-bar-toggle',
	NOTIFICATION_RECEIVED: 'notification-received',
	USER_LOGGED_IN: 'user-logged-in',
	USER_LOGGED_OUT: 'user-logged-out'
	// Add more event strings as needed
}