import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_treeview = _resolveComponent("v-treeview")!

  return (_openBlock(), _createBlock(_component_v_treeview, {
    items: $props.treeNodes,
    "item-value": "id",
    density: "compact",
    activatable: ""
  }, null, 8 /* PROPS */, ["items"]))
}