import { inject, Ref } from 'vue';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { DocGenDocumentSource, DocGenPutRequest, DocGenPutResponse, GenerateDocsRequest, GenerateDocsResponse } from '@/models/PrintDocumentModel';
import { MessageService, MessageEnvelope, MessageProperties } from '@/contracts/MessageService';
import { OperationResult } from '@/contracts/OperationResult';
import { useCommercialApi } from '@/composables/UseCommercialApi';
import { useFetch } from '@/composables/UseFetch';
import { useOperationMessaging } from '@/composables/UseOperationMessaging';
import { CommercialApiConfig } from '@/services/CommercialApiConfig'
import type { Tell } from '@/services/Tell';
import { ErrorScenarios } from '@/constants/errors';
import { UnhandledOperationError } from '@/errors/Errors';

export function usePrintDocumentsLogic(
    policyNumber: Ref<string | null>,
    occurrences: Ref<any[]>,
    htmlContent: Ref<string | null>,
    pdfContent: Ref<string | null>
) {
    const tell = inject('tell') as Tell;
    const telemetry = inject('telemetry') as ApplicationInsights;
    const messageService = inject('messageService') as MessageService;

    const { docsDocGenRequest, putDocGenForm, loading } = useCommercialApi(CommercialApiConfig);
    const { toOperationResult, fetchResilientWithAuth } = useFetch();
    const { withMessaging } = useOperationMessaging();

    const onPageLoad = async () => {
        await withMessaging('GenerateDocuments', async () => {

            const request = MessageEnvelope.fromObject(
                'GenerateDocsRequest',
                {
                    policyNumber: policyNumber.value ?? '',
                    requestedBy: 'User',
                },
                new MessageProperties({ correlationId: crypto.randomUUID() })
            );

            const result = await docsDocGenRequest(request);

            if (!result.success) {
                throw result.exception instanceof Error
                    ? result.exception
                    : new UnhandledOperationError();
            }

        });
    };

    const registerHandlers = () => {
        messageService.onMessage<DocGenDocumentSource>('WipReady', async (payload) => {
            await handleWipReady(payload);
        });
    };

    const unregisterHandlers = () => {
        messageService.offMessage('docsGetDocGen', () => {
            console.log('Unsubscribed from docsGetDocGen');
        });
    };

    const handleWipReady = async (payload: DocGenDocumentSource) => {
        await withMessaging('LoadDocuments', async () => {
            const result = await getDocumentSource(payload);

            if (!result.success) {
                throw result.exception instanceof Error
                    ? result.exception
                    : new UnhandledOperationError();
            }

            resolveContentUrl(payload, result.data?.contentUrl);
        });
    };

    const handleOperationMessages = (result: OperationResult<any>) => {
        // assume error messages are always customer-facing until
        // we can add enhancements to the OperationResult model
        result.messages.forEach(({ type, content }) => {
            if (type === 'info') tell?.info(content);
            if (type === 'error') tell?.error(content);
        });
        if (result.hasException) telemetry?.trackException(result.exception);
        return result.success;
    };

    const getDocumentSource = async (documentSource: DocGenDocumentSource): Promise<OperationResult<DocGenDocumentSource>> => {
        const url = new URL(documentSource.contentUrl);
        url.searchParams.append('policyNumber', documentSource.policyNumber);
        return toOperationResult(fetchResilientWithAuth, url.toString(), { method: 'GET' });
    };

    const resolveContentUrl = (documentSource: DocGenDocumentSource, url?: string) => {
        if (documentSource.contentSource === 'GhostDraft') {
            pdfContent.value = url ?? '';
        } else if (documentSource.contentSource === 'DocProd') {
            htmlContent.value = url ?? '';
        }
    };

    const collectFormValues = () => {
        const values: Record<string, any> = {};
        occurrences.value.forEach((occurrence) => {
            values[occurrence.name] = occurrence.inputValue;
        });
        return values;
    };

    const submitForm = async () => {
        try {
            const formValues = collectFormValues();
            await putDocGenForm<DocGenPutResponse>(
                new DocGenPutRequest(formValues, policyNumber.value),
                async (response) => {
                    handleOperationMessages(response);
                }
            );
        } catch (error) {
            const err = ErrorScenarios.formSubmitFailure(error);

            tell?.error(err.userMessage);
            telemetry?.trackException({
                exception: error instanceof Error ? error : new Error(err.telemetryMessage),
                properties: { source: 'SubmitForm' }
            });
        }
    };

    return {
        onPageLoad,
        registerHandlers,
        unregisterHandlers,
        submitForm,
        loading,
    };
}
